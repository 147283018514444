export const types = [
    {id: '$and', name: 'AND'},
    {id: '$or', name: 'OR'}
];

export const conditionTypes = [
    {id: '$eq', name: '=', bool: true},
    {id: 'NOT', name: 'NOT', bool: true},
    {id: '$gt', name: '>', number: true, string: false},
    {id: '$gte', name: '>=', number: true, string: false},
    {id: '$lt', name: '<', number: true, string: false},
    {id: '$lte', name: '<=', number: true, string: false},
    {id: 'CONTAINS', name: 'CONTAINS', number: false, string: true}
];

const getFieldName = (obj) => {
    return Object.keys(obj)[0];
}

const isGroup = (opType, typeDataSource) => {        
    return !!typeDataSource.find(t => t.id === opType);
}

const createConditions = (condition, typeDataSource) => {           
    const type = getFieldName(condition);
    const conditions = [...condition[type].map(t => {
        const operand = getFieldName(t);
        if (!isGroup(operand, typeDataSource)) {
            const values = t[getFieldName(t)];
            const conditionType = getFieldName(values);
            const value = values[conditionType];
            return {conditionType, fieldId: operand, value}
        } else {                
            return createViewModel(t, typeDataSource);
        }
    })];
    return conditions;
}

export const createViewModel = (condition) => {
    if (!condition) { return undefined; }
    const type = getFieldName(condition);
    const result = {
        group: true,
        type: type,
        conditions: createConditions(condition, types)
    }
    return result;
}

export const createModel = (c) => {
    if (!c) { return null; }

    const result = {};
    if (c.group) {            
        result[c.type] = c.conditions.map(t => {
            let res = createModel(t);
            return res;
        });
    } else {            
        const fieldValue = {};
        fieldValue[c.conditionType] = c.value;
        result[c.fieldId] = fieldValue;
    }
    return result;
}

const validateConditionField = (c, fields) => {
    if (!c.fieldId || !fields.find(t => t.id === c.fieldId)) {
        return true;
    }

    if (!c.conditionType) {
        return false;
    } else {
        const conditionType = conditionTypes.find(t => t.id === c.conditionType);
        const isNumber = !!(Number.parseFloat(c.value) || c.value === '0');

        if (conditionType.number && !isNumber) {
            return false;
        }
    }

    return true;
}

export const validateCondition = (c, fields = []) => {
    if (!c) { return true; }

    if (c.group) {
        return c.conditions.map(t => validateCondition(t, fields)).filter(t => t === false).length === 0;
    } else {
        return validateConditionField(c, fields);
    }
}

const getNumberValue = (value) => {
    const number = Number.parseInt(value);
    return number;
}
export const calculateFieldCondition = (c, p) => {   
    const fields = [...Object.keys(p).map(t => {return {id: t, name: t}})];

    if (!validateConditionField(c, fields)) { 
        return true;
    }

    const fieldValue = p[c.fieldId];
    const value = c.value;

    if (fieldValue === undefined) { return false }

    switch (c.conditionType) {
        case '$eq': return fieldValue === value;
        case 'NOT': return fieldValue !== value;
        case '$gt': return getNumberValue(fieldValue) > value;
        case '$gte': return getNumberValue(fieldValue) >= value;
        case '$lt': return getNumberValue(fieldValue) < value;
        case '$lte': return getNumberValue(fieldValue) <= value;
        case 'CONTAINS': return fieldValue && value && fieldValue.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        default: return true;
    }
}

export const calculateCondition = (c, product) => {
if (!c) { return true; }

    if (c.group) {
        const results = c.conditions.map(t => calculateCondition(t, product));
        return c.type === '$and' ? 
            results.filter(t => t === true).length === results.length :
            results.filter(t => t === true).length > 0;
    } else {
        return calculateFieldCondition(c, product);
    }
}