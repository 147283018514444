import React, {useEffect, useRef, useState} from 'react'
import Konva from 'konva';
import {message} from "antd";
import {useHistory, withRouter} from "react-router-dom";
import {axiosApi} from "../../axios-api";
import {KeyCombinations, SetEditableByDblClick, useWindowKeyDown, useWindowSize} from "./GraphicEditorHelpers";
import {useDesignHistory} from './services/HistoryService';
import {calculateCondition, createViewModel} from './services/ConditionService';
import {scaleImage, scaleRect, scaleText} from './services/ScaleService';
import {cleanProductImageGroup} from './services/ProductHelperService';
import {DEFAULT_IMAGE} from "../../config";
import GraphicProperties from "./Panels/GraphicPropertiesSidebar/GraphicPropertiesSidebar";
import GraphicEditorHeader from "./Panels/GraphicEditorHeader/GraphicEditorHeader";
import LayersPanel from './Panels/LayersSidebar/GraphicLayersPanel';
import GraphicEditorTooltip from "./components/GraphicEditorTooltip/GraphicEditorTooltip";
import {setMenuPosition} from "./services/MenuService";
import {roundPositionAndSize} from './services/PropertiesService';
import {handleGenerationsLimitError, removeDuplicates} from '../../helpers';
import FontsPanel from './../fontsEditor/FontsEditor';
import ProductData from './GraphicProductData';
import ControlsPanel from './components/ControlsPanel';
import ProjectProperties from './components/ProjectProperties/ProjectProperties';
import rotateCursor from '../../svg/icon/rotate-cursor.png';
import { transformerAnchors, transformerAnchorsFull } from '../../constants';


const GraphicEditor = (props) => {
    const sidebarWidth = 284;
    const marginXOffSet = 32;
    const marginYOffSet = 64;
    const headerTop = 75;
    const layerDiff = 320;
    const maxDepth = 3;
    const api = props.backend;
    const projectId = props.match.params.project_id;
    const designId = props.match.params.design_id === 'new' ? undefined : props.match.params.design_id;

    const urlHistory = useHistory();

    const styles = {
        konva: {
            width: `100%`,
            position: 'absolute',
            top: headerTop,
            left: layerDiff,
            height: 'calc(100% - 4rem)',
            float: 'left',
            margin: 0,
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            backgroundColor: 'white'
        }
    }

    // data
    const [design, setDesign] = useState();
    const [project, setProject] = useState();
    const [ruleId, setRuleId] = useState();
    const [designName, setDesignName] = useState(null);
    const [feedPage, setFeedPage] = useState();
    const [feedPageIndex, setFeedPageIndex] = useState(0);
    const [productIndex, setProductIndex] = useState(0);
    const [product, setProduct] = useState();
    const [userImages, setUserImages] = useState([]);
    const [propertiesRefreshToggle, setPropertiesRefreshToggle] = useState(true);
    const [reloadingProducts, setReloadingProducts] = useState(false);
    const [allowChangeProduct, setAllowChangeProduct] = useState(true);
    const [productFields, setProductFields] = useState({});

    const [windowWidth, windowHeight] = useWindowSize();
    // stage

    const [stage, setStage] = useState(null);
    const [clipOutline, setClipOutline] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedElementPosition, setSelectedElementPosition] = useState({
      x: 0,
      y: 0,
    });
    const [presetsDataSource, setPresetsDataSource] = useState([
        {id: 'facebook banner 600x600 px', name: 'facebook banner 600x600 px', width: 600, height: 600},
        {id: 'default size 500x500 px', name: 'default size 500x500 px', width: 500, height: 500},
        {id: 'banner 400x500 px', name: 'banner 400x500 px', width: 400, height: 500},
        {id: 'custom user preset', name: 'custom user preset', customSize: true},
    ]);
    const [canvasSize, setCanvasSize] = useState({
        width: presetsDataSource[0].width,
        height: presetsDataSource[0].height
    });

    const [canvasPosition, setCanvasPosition] = useState({
        x: (windowWidth - marginXOffSet - layerDiff - sidebarWidth - canvasSize.width) / 2,
        y: (windowHeight - marginYOffSet - canvasSize.height) / 2
    });

    const [preset, setPreset] = useState();

    //properties
    const [isLayersPanelOpen, setLayersPanelOpen] = useState(true);
    const [isFontsPanelOpen, setFontsPanelOpen] = useState();
    const [isProductDataPanelOpen, setProductDataPanelOpen] = useState();
    const [isProductPropertiesOpen, setProductPropertiesOpen] = useState(false);

    const [keyFired, setKeyFiredProcessed] = useWindowKeyDown();
    const [saveHistory, history, historyUndo, historyRedo] = useDesignHistory();

    useEffect(() => {
        if (stage) {
            stage.width(windowWidth - sidebarWidth - marginXOffSet);
            stage.height(windowHeight - marginYOffSet);
        }
    }, [stage, windowWidth, windowHeight, sidebarWidth])

    useEffect(() => {
        initProject()
    }, [])

    useEffect(() => {
        if (keyFired) {
            if (keyFired === KeyCombinations.ctrlG) {
                const isGroupSelected = selectedGroup && selectedGroup.attrs.elementType === 'group' && selectedGroup.hasName('elementGroup');
                if (isGroupSelected) {
                    destroyGroup(selectedGroup);
                } else {
                    createGroup();
                }
            }
            if (keyFired === KeyCombinations.ctrlD) {
                copyElement(selectedGroup);
            }
            if (keyFired === KeyCombinations.delete || keyFired === KeyCombinations.backspace) {
                deleteElement(selectedGroup);
            }
            if (keyFired === KeyCombinations.ctrlZ) {
                historyUndoAction(stage);
            }
            if (keyFired === KeyCombinations.ctrlY) {
                historyRedoAction(stage);
            }
        }
        setKeyFiredProcessed();
    }, [keyFired])

    useEffect(() => {
        if (design) {
            setDesignName(design.name);
            setStage(loadStage());
        }
    }, [design])

    useEffect(() => {
        const menuNode = document.getElementById('menu');

        if (selectedGroup) {
            stage.findOne('#selectionRectangle').moveToTop();
            const tr = stage.findOne('Transformer');
            tr.moveToTop();
            refreshProperties();

            let selectedBackground = selectedGroup.findOne('.background');
            if (!selectedGroup.attrs.name) {
                selectedBackground = selectedGroup.findOne('.back');
            }
            setSelectedElementPosition({
              x: selectedGroup.x(),
              y: selectedGroup.y(),
            });

            setMenuPosition(stage, selectedGroup, selectedBackground, layerDiff);
        } else {
            menuNode.style.display = 'none';
        }

        refreshLayersStructure();

        setProductDataPanelOpen(isProductDataPanelOpen && selectedGroup ? false : isProductDataPanelOpen);
    }, [selectedGroup])

  useEffect(() => {
    if (selectedGroup) {
      let selectedBackground = selectedGroup.findOne('.background');
      if (!selectedGroup.attrs.name) {
        selectedBackground = selectedGroup.findOne('.back');
      }
      setMenuPosition(stage, selectedGroup, selectedBackground, layerDiff);
      refreshLayersStructure();
    }

  }, [selectedGroup, selectedElementPosition]);

    useEffect(() => {
        const currentIndex = productIndex % feedPage?.page_size;
        if (feedPage?.products.length && currentIndex < feedPage.products.length) {
            if (!reloadingProducts) {
                setProduct(feedPage.products[currentIndex])
            }
        }
    }, [feedPage, productIndex, reloadingProducts])

    useEffect(() => {
        if (project && project.feed?.feed_fields) {
            setProductFields(JSON.parse(project.feed.feed_fields));
        }
    }, [project])

    useEffect(() => {
        const getFeedPage = async () => {
          setReloadingProducts(true);
          setFeedPage(null);
          setProduct(null);
          try {
                if (project) {
                    const {data} = await axiosApi.get(`/feed/?feed_id=${project.feed.id}&page_num=${feedPageIndex}`);
                    setFeedPage(data);
                }
            } catch (error) {
                message.error(error.message);
            }
            finally {
            setReloadingProducts(false);
          }
        };

        getFeedPage();

    }, [project, feedPageIndex]);

    const setElementVisibility = (element, value) => {
        element.setVisible(value);
        element.setAttr('invisible', !value);
    }

    useEffect(() => {
        if (product) {
            setAllowChangeProduct(false);

            const promises = [];
            const textsList = stage.find('Text');
            const imageGroups = stage.find('.elementGroup').filter((group) => group?.attrs?.elementType === 'image');
            for (const imageGroup of imageGroups) {
              if(imageGroup?.children.length > 2) {
                const [ background, image ] = cleanProductImageGroup(imageGroup.children);
                imageGroup.destroyChildren();
                imageGroup.add(background, image);
              }
            }
            const imagesList = stage.find('Image');

            if (!!textsList.length) {
                textsList.forEach((text) => {
                    if (text.getAttr('dynamicField')) {
                        const textValue = product[text.getAttr('dynamicField')] ? product[text.getAttr('dynamicField')] : text.getAttr('placeholder');
                        text.text(textValue);
                        text.setAttr('fullText', textValue);

                        if (!textValue) {
                            setElementVisibility(text.parent, false);
                        } else {
                            setElementVisibility(text.parent, true);
                            promises.push(new Promise((res, rej) => {
                                scaleText(text, res, rej)
                            }))
                        }
                    }
                })
            }

            if (!!imagesList.length) {
                imagesList.forEach((image) => {
                    if (image.getAttr('dynamicField')) {
                        const group = image.parent
                        const rect = group.findOne('.background')
                        const imgAttrs = image.getAttrs()
                        const imageObj = new window.Image();

                        promises.push(new Promise((res, rej) => {
                            imageObj.onload = function () {
                                const oldImage = imgAttrs.image
                                delete imgAttrs.image
                                delete imgAttrs.width
                                delete imgAttrs.height
                                const imgNode = new Konva.Image({
                                    ...imgAttrs,
                                    image: imageObj,
                                    url: product[imgAttrs.dynamicField],
                                    initWidth: imageObj.width,
                                    initHeight: imageObj.height,
                                });
                                rect.fillPatternImage(imageObj);
                                image.destroy();
                                group.add(imgNode);
                                if (oldImage) oldImage.remove();
                                scaleImage(imgNode)
                                res()
                            };
                            imageObj.onerror = rej;
                            imageObj.src = product[imgAttrs.dynamicField];
                        }))
                    }
                })
            }

            stage.find('.condition').forEach((el) => {
                checkCondition(el);
            });
            let distributedChildren = []
            stage.find('.distributed').forEach(g => {
                distributedChildren = [...distributedChildren, ...g.children]
            })

            stage.find('.target').forEach(el => {
                if (!el.hasName('source') && !distributedChildren.includes(el)) {
                    promises.push(new Promise((res) => {
                        setTimeout(() => {
                            moveAttachedSources(el)
                            res()
                        }, 500) // TODO: избавится бы, где-то асинхронщина
                    }))
                }
            })
            stage.draw()
            stage.find('.distributed').forEach((el) => {
                reDistributeGroup(el)
            })
            Promise.all(promises).then(
                () => {
                    setAllowChangeProduct(true)
                },
                () => {
                    setAllowChangeProduct(true)
                }
            )
        }
    }, [product])

    useEffect(() => {
        if (stage) {
            stage.findOne('#canvas').clip(
                clipOutline ? {
                    x: canvasPosition.x,
                    y: canvasPosition.y,
                    width: canvasSize.width,
                    height: canvasSize.height,
                } : {
                    x: undefined,
                    y: undefined,
                    width: undefined,
                    height: undefined,
                }
            )
        }
    }, [clipOutline])

    useEffect(() => {
        if (stage) {
            const canvas = stage.findOne('#canvas')
            const newSize = {
                width: canvasSize.width,
                height: canvasSize.height
            }
            stage.setAttr('canvas_width', canvasSize.width);
            stage.setAttr('canvas_height', canvasSize.height);
            stage.findOne('#canvas_bg').size(newSize)
            stage.findOne('#outline_bg').size(newSize)
            if (clipOutline) canvas.clip({
                ...newSize, x: canvasPosition.x, y: canvasPosition.y
            })
        }
    }, [stage, canvasSize, clipOutline, canvasPosition])

    useEffect(() => {
        if (stage && canvasPosition) {
            const captionLayer = stage.findOne('#captionLayer');
            const textElement = captionLayer.findOne('Text');
            if (textElement) {
                textElement.text(getDesignViewName(designName, preset, canvasSize.width, canvasSize.height));
            }
            const images = captionLayer.find('Image');
            if (images && images.length > 1) {
                const image = images[images.length - 1];
                image.x(canvasPosition.x + textElement.textWidth + 35);
            }
            captionLayer.draw();
        }
    }, [preset, designName])

    useEffect(() => {
        if (stage) {
            stage.setAttr('preset_name', presetsDataSource.find(t => t.id === preset).name);
        }
    }, [preset]);

    const initProject = () => {
        loadProject(projectId);
        if (designId) {
            loadDesign();
        } else {
            setStage(createStage())
        }
    };
    // stage
    const createStage = () => {
        const stageWidth = 'calc(100% - 210px - 2rem)';
        const stageHeight = 'calc(100% - 4rem)';

        const stage = new Konva.Stage({
            width: stageWidth,
            height: stageHeight,
            container: 'konva_container',
            draggable: true,
            canvas_width: canvasSize.width,
            canvas_height: canvasSize.height,
            preset_name: presetsDataSource[0].name,
        })
        const canvasLayer = new Konva.Layer({
            id: 'canvas',
            clip: {
                x: canvasPosition.x,
                y: canvasPosition.y,
                width: canvasSize.width,
                height: canvasSize.height,
            }
        });
        canvasLayer.add(
            new Konva.Rect({
                id: 'canvas_bg',
                stroke: 'gray',
                strokeWidth: 1,
                opacity: 1,
                fill: 'white',
                x: canvasPosition.x,
                y: canvasPosition.y,
                width: canvasSize.width,
                height: canvasSize.height,
                listening: false
            })
        )
        stage.add(canvasLayer);
        initStageFunctions(stage, canvasLayer);
        setDesignName('noname template');

        return stage
    }

    const loadStage = () => {
        if (!design || !design.design_json) return;

        const stage = Konva.Node.create(
            design.design_json,
            'konva_container'
        );

        setCanvasPosition(stage.findOne('#canvas_bg').position())
        setCanvasSize({
            width: stage.getAttr('canvas_width'),
            height: stage.getAttr('canvas_height'),
        })

        stage.width(window.innerWidth - sidebarWidth);
        stage.height(window.innerHeight);
        stage.findOne('#canvas_bg').setAttrs({stroke: 'gray',  strokeWidth: 1});

        const textsList = stage.find('Text');
        const imagesList = stage.find('Image');

        const canvasLayer = stage.findOne('#canvas');

        if (!!textsList.length) {
            for (let text of stage.find('Text')) {
                const isDynamicText = text.getAttr('dynamicField');

                if (!isDynamicText) {
                    SetEditableByDblClick(text, stage, () => {
                        refreshProperties();
                    });
                }
            }
        }

        if (!!imagesList.length) {
            for (let imgEl of imagesList) {

                const isDynamicImage = imgEl.getAttr('dynamicField')

                if (!isDynamicImage) {
                    const group = imgEl.parent
                    const rect = group.findOne('.background')
                    const imgAttrs = imgEl.getAttrs()
                    const imageObj = new window.Image();
                    imageObj.onload = function () {
                        const oldImage = imgAttrs.image
                        delete imgAttrs.image
                        delete imgAttrs.width
                        delete imgAttrs.height
                        const imgNode = new Konva.Image(
                            {
                                ...imgAttrs, image: imageObj,
                                initWidth: imageObj.width,
                                initHeight: imageObj.height,
                            }
                        );
                        rect.fillPatternImage(imageObj);
                        group.add(imgNode);
                        if (oldImage) oldImage.remove();
                        imgEl.destroy();
                        scaleImage(imgNode)
                    };
                    imageObj.src = imgAttrs.url;
                }
            }
        }

        const unnecessaryTransformer = stage.findOne('Transformer');
        if (unnecessaryTransformer) {
            unnecessaryTransformer.destroy();
        }

        initStageFunctions(stage, canvasLayer);

        return stage
    }

    const initStageFunctions = (stage, layer) => {
        setTransformer(stage, layer);
        setGuidLines(stage, layer);
        setDragListeners(layer);
        addOutlineLayer(stage);
        addCaptionLayer(stage);
        setZoomOnScroll(stage);
        loadPresetData(stage);
    }

    const setTransformer = (stage, layer) => {
        const tr = new Konva.Transformer({
            elementType: 'group',
            rotationSnaps: [0, 90, 180, 270]
        });

        tr.boundBoxFunc(function (oldBox, newBox) {
            if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
            }
            return newBox;
        });

        tr.on('mouseenter', (e) => {
            const anchor = e.target;
            if (anchor.getAttr('name').indexOf('rotater') > -1 && anchor.getStage().content) {
                anchor.getStage().content.style.cursor = `url("${rotateCursor}") 8 8, auto`;
            }
        });

        tr.on('mouseout', (e) => {
            const anchor = e.target;
            if (anchor.getAttr('name').indexOf('rotater') > -1) {
                anchor.getStage().content && (anchor.getStage().content.style.cursor = '');
            }
        });

        layer.add(tr);

        const selectionRectangle = new Konva.Rect({
            draggable: false,
            fill: 'rgba(0,0,255,0.5)',
            visible: false,
            id: 'selectionRectangle'
        });
        layer.add(selectionRectangle);

        const toTop = () => {
            selectionRectangle.moveToTop();
            tr.moveToTop();
        }
        const menuNode = document.getElementById('menu');

        let x1, y1, x2, y2;
        stage.on('mousedown touchstart', (e) => {
            // do nothing if we mousedown on any shape
            if (e.target !== stage) {
                return;
            }
            x1 = stage.getPointerPosition().x;
            y1 = stage.getPointerPosition().y;
            x2 = stage.getPointerPosition().x;
            y2 = stage.getPointerPosition().y;

            selectionRectangle.visible(true);
            selectionRectangle.width(0);
            selectionRectangle.height(0);
            toTop();
        });

        stage.on('dragmove', () => {
            const nodes = tr.nodes();

            if (nodes.length === 0) return;
            const backgroundWidth =  nodes[0].findOne('.background');

            if (backgroundWidth) {
                setMenuPosition(stage,  nodes[0], backgroundWidth, layerDiff);
            }
        });

        stage.on('mousemove touchmove', () => {
            // do nothing if we didn't start selection
            if (!selectionRectangle.visible()) {
                return;
            }
            x2 = stage.getPointerPosition().x;
            y2 = stage.getPointerPosition().y;

            selectionRectangle.setAttrs({
                x: Math.min(x1, x2),
                y: Math.min(y1, y2),
                width: Math.abs(x2 - x1),
                height: Math.abs(y2 - y1),
            });
            toTop();
        });

        stage.on('mouseup touchend', () => {
            // do nothing if we didn't start selection
            if (!selectionRectangle.visible()) {
                return;
            }
            // update visibility in timeout, so we can check it in click event
            setTimeout(() => {
                selectionRectangle.visible(false);
                menuNode.style.display ='none';
            });

            const shapes = stage.find('.background');
            const box = selectionRectangle.getClientRect();
            const selected = shapes.filter((shape) =>
                Konva.Util.haveIntersection(box, shape.getClientRect())
            );
            const selectedGroups = [];
            for (const el of selected) {
                let group = el.parent;
                if (selectedGroups.indexOf(group) < 0) {
                    selectedGroups.push(group)
                }
            }
            tr.nodes(selectedGroups);
            toTop();
        });

        // clicks should select/deselect shapes
        stage.on('click tap', function (e) {
            // if we are selecting with rect, do nothing
            if (selectionRectangle.visible()) {
                menuNode.style.display = 'none';
                tr.nodes([]);
                setSelectedGroup(null);
                refreshLayersStructure();
                return;
            }

            // if click on empty area - remove all selections
            if (e.target === stage) {
                menuNode.style.display = 'none';
                tr.nodes([]);
                setSelectedGroup(null);
                refreshLayersStructure();
                return;
            }

            // do nothing if clicked NOT on our rectangles
            if (!e.target.hasName('element')) {
                menuNode.style.display = 'none';
                return;
            }

            let target = e.target.parent

            if (target.parent.getAttr('elementType') === 'group') {
                target = target.parent
            }

            // do we pressed shift or ctrl?
            const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
            const isSelected = tr.nodes().indexOf(target) >= 0;

            if (!metaPressed && !isSelected) {
                // if no key pressed and the node is not selected
                // select just one
                setSelectedGroup(target);
                tr.nodes([target]);
                const image = target.findOne('.main');
                if (image && image.getAttr('elementType') === 'image' && image.getAttr('mode') === 'flex') {
                  tr.enabledAnchors(transformerAnchors);
                } else {
                  tr.enabledAnchors(transformerAnchorsFull);
                }
                menuNode.style.display = 'initial';
            } else if (metaPressed && isSelected) {
                // if we pressed keys and node was selected
                // we need to remove it from selection:
                const nodes = tr.nodes().slice(); // use slice to have new copy of array
                // remove node from array
                nodes.splice(nodes.indexOf(target), 1);
                tr.nodes(nodes);
                if (tr.nodes().length === 1) {
                    setSelectedGroup(tr.nodes()[0]);
                } else if (tr.nodes().length > 1) {
                    setSelectedGroup(tr);
                } else {
                    setSelectedGroup(null);
                }
            } else if (metaPressed && !isSelected) {
                // add the node into selection
                const nodes = tr.nodes().concat([target]);
                tr.nodes(nodes);
                if (tr.nodes().length === 1) {
                    setSelectedGroup(tr.nodes()[0]);
                } else if (tr.nodes().length > 1) {
                    setSelectedGroup(tr);
                }
            }
            toTop();
            refreshLayersStructure()
        });

        stage.find('.elementGroup').forEach((el) => {
            el.on('transformend', handleTransformEnd)
        })
    }

    const setGuidLines = (stage, layer) => {
        // were can we snap our objects?
        function getLineGuideStops(skipShape) {
            // we can snap to stage borders and the center of the stage
            const vertical = [
                canvasPosition.x + stage.x(),
                canvasPosition.x + stage.x() + canvasSize.width / 2,
                canvasPosition.x + stage.x() + canvasSize.width
            ];
            const horizontal = [
                canvasPosition.y + stage.y(),
                canvasPosition.y + stage.y() + canvasSize.height / 2,
                canvasPosition.y + stage.y() + canvasSize.height
            ];


            // and we snap over edges and center of each object on the canvas
            stage.find('.elementGroup').forEach((guideItem) => {
                if (skipShape instanceof Konva.Transformer) {
                    if (skipShape.nodes().indexOf(guideItem) >= 0) {
                        return;
                    }
                } else if (
                    guideItem === skipShape ||
                    skipShape.find('.elementGroup').indexOf(guideItem) >= 0
                ) {
                    return;
                }
                const box = guideItem.getClientRect();
                // and we can snap to all edges of shapes
                vertical.push([
                    box.x,
                    box.x + box.width / 2,
                    box.x + box.width,
                ])
                horizontal.push([
                    box.y,
                    box.y + box.height / 2,
                    box.y + box.height,
                ])
            });
            return {
                vertical: vertical.flat(),
                horizontal: horizontal.flat(),
            };
        }

        // what points of the object will trigger to snapping?
        // it can be just center of the object
        // but we will enable all edges and center
        function getObjectSnappingEdges(node) {
            const box = node.getClientRect();
            const absPos = node.absolutePosition();

            return {
                vertical: [
                    {
                        guide: Math.round(box.x),
                        offset: Math.round(absPos.x - box.x),
                        snap: 'start',
                    },
                    {
                        guide: Math.round(box.x + box.width / 2),
                        offset: Math.round(absPos.x - box.x - box.width / 2),
                        snap: 'center',
                    },
                    {
                        guide: Math.round(box.x + box.width),
                        offset: Math.round(absPos.x - box.x - box.width),
                        snap: 'end',
                    },
                ],
                horizontal: [
                    {
                        guide: Math.round(box.y),
                        offset: Math.round(absPos.y - box.y),
                        snap: 'start',
                    },
                    {
                        guide: Math.round(box.y + box.height / 2),
                        offset: Math.round(absPos.y - box.y - box.height / 2),
                        snap: 'center',
                    },
                    {
                        guide: Math.round(box.y + box.height),
                        offset: Math.round(absPos.y - box.y - box.height),
                        snap: 'end',
                    },
                ],
            };
        }

        // find all snapping possibilities
        function getGuides(lineGuideStops, itemBounds) {
            const resultV = [];
            const resultH = [];
            const GUIDELINE_OFFSET = 5;

            lineGuideStops.vertical.forEach((lineGuide) => {
                itemBounds.vertical.forEach((itemBound) => {
                    const diff = Math.abs(lineGuide - itemBound.guide);
                    // if the distance between guild line and object snap point is close we can consider this for snapping
                    if (diff < GUIDELINE_OFFSET) {
                        resultV.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });

            lineGuideStops.horizontal.forEach((lineGuide) => {
                itemBounds.horizontal.forEach((itemBound) => {
                    const diff = Math.abs(lineGuide - itemBound.guide);
                    if (diff < GUIDELINE_OFFSET) {
                        resultH.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });

            const guides = [];

            // find closest snap
            const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
            const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
            if (minV) {
                guides.push({
                    lineGuide: minV.lineGuide,
                    offset: minV.offset,
                    orientation: 'V',
                    snap: minV.snap,
                });
            }
            if (minH) {
                guides.push({
                    lineGuide: minH.lineGuide,
                    offset: minH.offset,
                    orientation: 'H',
                    snap: minH.snap,
                });
            }
            return guides;
        }

        function drawGuides(guides) {
            guides.forEach((lg) => {
                if (lg.orientation === 'H') {
                    const line = new Konva.Line({
                        points: [-6000, 0, 6000, 0],
                        stroke: 'rgb(0, 161, 255)',
                        strokeWidth: 1,
                        name: 'guid-line',
                        dash: [4, 6],
                    });
                    layer.add(line);
                    line.absolutePosition({
                        x: 0,
                        y: lg.lineGuide,
                    });
                } else if (lg.orientation === 'V') {
                    const line = new Konva.Line({
                        points: [0, -6000, 0, 6000],
                        stroke: 'rgb(0, 161, 255)',
                        strokeWidth: 1,
                        name: 'guid-line',
                        dash: [4, 6],
                    });
                    layer.add(line);
                    line.absolutePosition({
                        x: lg.lineGuide,
                        y: 0,
                    });
                }
            });
        }

        layer.on('dragmove', function (e) {
            const target = e.target;
            const nodes = stage.findOne('Transformer').nodes()
            if (nodes.indexOf(target) >= 0 && !(target instanceof Konva.Transformer)) {
                return
            }

            // clear all previous lines on the screen
            layer.find('.guid-line').forEach((l) => l.destroy());

            // find possible snapping lines
            const lineGuideStops = getLineGuideStops(target);
            // find snapping points of current object
            const itemBounds = getObjectSnappingEdges(target);

            // now find where can we snap current object
            const guides = getGuides(lineGuideStops, itemBounds);

            // do nothing of no snapping
            if (!guides.length) {
                return;
            }

            drawGuides(guides);

            const absPos = target.absolutePosition();
            // now force object position
            guides.forEach((lg) => {
                switch (lg.snap) {
                    case 'start': {
                        switch (lg.orientation) {
                            case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                    case 'center': {
                        switch (lg.orientation) {
                            case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                    case 'end': {
                        switch (lg.orientation) {
                            case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                            }
                            case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                            }
                        }
                        break;
                    }
                }
            });
            target.absolutePosition(absPos);

            const backgroundWidth = nodes.length && nodes.length === 1 ?  nodes[0].findOne('.background') : target.findOne('.back');
            const targetElement = target.hasName('condition') ? target.parent : target;
             if (backgroundWidth) {
                setMenuPosition(stage, nodes.length && nodes.length === 1 ? nodes[0] : targetElement, backgroundWidth, layerDiff);
            }
        });

        layer.on('dragend', function () {
            layer.find('.guid-line').forEach((l) => l.destroy());
            refreshProperties();

            saveHistory(stage);
        });
    }


    useEffect(() => {
        if (stage) {
            saveHistory(stage);
        }
    }, [stage]);

    const afterHistoryChangedAction = (stage, layer) => {
        for (const text of layer.find('Text')) {
            scaleText(text);
        }

        for (const image of layer.find('Image')) {
            scaleImage(image)
        }

        stage.draw();
        layer.draw();

        refreshLayersStructure();
        closeProperties();
    }

    const historyUndoAction = () => {
        historyUndo(stage, deleteElement, afterHistoryChangedAction)
    }

    const historyRedoAction = () => {
        historyRedo(stage, deleteElement, afterHistoryChangedAction);
    }

    const setDragListeners = (layer) => {
        layer.on('dragmove', function (e) {
            const target = e.target;
            if (target.hasName('target')) {
                moveAttachedSources(target)
            }
            if (target.hasName('source')) {
                updateSourceAttachedOffset(target)
            }
        })
    }

    const addOutlineLayer = (stage) => {
        const outlineLayer = new Konva.Layer({
            id: 'outlineLayer',
        });
        const bg = stage.findOne('#canvas_bg')
        outlineLayer.add(new Konva.Shape({
            id: 'outline_bg',
            x: bg.x(),
            y: bg.y(),
            width: canvasSize.width,
            height: canvasSize.height,
            fill: 'rgba(249,249,249,0.5)',
            sceneFunc: (ctx, shape) => {
                ctx.beginPath();
                const bord = 100000;
                ctx.moveTo(-bord, -bord);
                ctx.lineTo(shape.width() + bord, -bord);
                ctx.lineTo(shape.width() + bord, shape.height() + bord);
                ctx.lineTo(-bord, shape.height() + bord);
                ctx.lineTo(-bord, shape.height());
                ctx.lineTo(shape.width(), shape.height());
                ctx.lineTo(shape.width(), 0);
                ctx.lineTo(0, 0);
                ctx.lineTo(0, shape.height());
                ctx.lineTo(-bord, shape.height());
                ctx.lineTo(-bord, -bord);
                ctx.closePath();
                ctx.fillStrokeShape(shape);
            },
            listening: false
        }))
        stage.add(outlineLayer);
    }

    const showProjectPropertyForm = () => {
        setProductPropertiesOpen(!isProductPropertiesOpen);
    }

    const addCaptionLayer = (stage) => {
        const captionLayer = new Konva.Layer({
            id: 'captionLayer'
        });
        const bg = stage.findOne('#canvas_bg')

        const text = new Konva.Text({
            x: bg.x(),
            y: bg.y() - 20,
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fill: '#979797',
            fontSize: 16,
            style: {cursor: 'pointer'},
            text: getDesignViewName(designName, preset, stage.getAttr('canvas_width'), stage.getAttr('canvas_height'))
        });

        text.on('mouseenter', function () {
            stage.container().style.cursor = 'pointer';
        });

        text.on('mouseleave', function () {
            stage.container().style.cursor = 'default';
        });

        text.on('click', function () {
            showProjectPropertyForm();
        });

        captionLayer.add(text);

        stage.add(captionLayer);
    }

    const loadPresetData = (stage) => {
        if (stage) {
            let preset = stage.getAttr('preset_name');
            if (preset) {
                const exist = presetsDataSource.find(t => t.name === preset);
                if (!exist) {
                    const newItems = [...presetsDataSource];
                    newItems.push({id: preset, name: preset, width: canvasSize.width, height: canvasSize.height});
                    setPresetsDataSource(newItems);
                }
            } else {
                preset = presetsDataSource[presetsDataSource.length - 1].id;
            }
            setPreset(preset);
        }
    }

    const setZoomOnScroll = (stage) => {
        stage.on('scroll wheel', (e) => {
            e.evt.preventDefault();
            const scaleBy = 1.01;
            scaleStage(stage, scaleBy, e.evt.deltaY);
        })
    }

    const zoomStage = (deltaY) => {
        const scaleBy = 1.1;
        scaleStage(stage, scaleBy, deltaY);
    }

    const scaleStage = (stage, scaleBy, deltaY) => {
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };
        const newScale = deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        stage.scale({x: newScale, y: newScale});

        const newPos = {
            x:
                -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
                newScale,
            y:
                -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
                newScale
        };
        stage.position(newPos);
        stage.draw();

        if (selectedGroup) {
            const selectedBackground = selectedGroup.findOne('.background');
            if (selectedBackground) {
                setMenuPosition(stage, selectedGroup,selectedBackground, layerDiff);
            }
        }

    }

    const switchScale = async (target, scale) => {
        switch (target.getAttr('elementType')) {
            case 'text':
                await scaleText(target.findOne('.main'))
                break
            case 'image':
                await  scaleImage(target.findOne('.main'))
                break
            case 'group':
                await  scaleGroup(target, scale)
                break
            case 'rect':
                await  scaleRect(target)
                break
            default:
                break
        }

        const selectedBackground = target.findOne('.background');
        const targetElement = target.hasName('condition') ? target.parent : target;

        setMenuPosition(stage, targetElement, selectedBackground, layerDiff);
    }

    const handleTransformEnd = (e) => {
        switchScale(e.target)
        e.target.getLayer().draw();
        if (e.target.hasName('target')) {
            moveAttachedSources(e.target)
        } else if (e.target.hasName('source')) {
            updateSourceAttachedOffset(e.target)
        }
        refreshProperties();

        saveHistory(e.target.getStage());
    }

    const scaleGroup = (group, scale) => {
        if (group.getAttr('elementType') === 'group') {
            const newScale = scale ?? group.scale()
            for (const child of group.children) {
                child.position({
                    x: child.x() * newScale.x,
                    y: child.y() * newScale.y,
                })
                if (['text', 'image'].includes(child.getAttr('elementType'))) {
                    child.scale(newScale)
                } else if (child.getAttr('elementType') !== 'group') {
                    child.scale({
                        x: child.scaleX() * newScale.x,
                        y: child.scaleY() * newScale.y,
                    })
                }
                switchScale(child, newScale)
            }
            group.scale({x: 1, y: 1});
        }
    }

    const canvasOnOff = () => {
        setClipOutline(!clipOutline);
    }

    const refreshProperties = () => {
        setPropertiesRefreshToggle(
            propertiesRefreshToggle => !propertiesRefreshToggle
        );
    }

    // data
    const loadDesign = async () => {
        try {
            const designData = await api.fetchDesign(projectId, designId);
            await api.setDesignFont(designData.fonts);

            setDesign(designData);
        } catch (error) {
            message.error(error.message);
            props.history.push('/');
        }
    }
    const loadProject = async () => {
        try {
            const result = await api.fetchProject(projectId);

            const foundOrganization = props.backend?.user?.roles.find((role) => role.organization === result.organization.id);
            let newProject = result;

            if (foundOrganization) {
	            await getImages(foundOrganization.organization);
	            await api.getFonts(foundOrganization.organization);

                if (!foundOrganization.generation_allowed) {
                    urlHistory.push('/admin');
                }

                newProject = {
                    ...result,
                    organization: {
                        ...result.organization,
                        ...foundOrganization,
                    }
                };
            }

            setProject(newProject);
            setRuleId(result.rule?.id);
        } catch (err) {
            props.history.push('/');
            message.error(err.message);
        }
    };

    const toNextProduct = (step = 1) => {
        if (allowChangeProduct) {
            setAllowChangeProduct(false);
            const nextProduct = productIndex + step
            const nextPage = parseInt(nextProduct / feedPage.page_size);
            if (nextProduct >= feedPage.total_products) {
                setFeedPageIndex(0);
                setProductIndex(0);
                setReloadingProducts(true);
            } else if (nextPage > feedPageIndex) {
                setFeedPageIndex(nextPage);
                setProductIndex(nextProduct);
                setReloadingProducts(true);
            } else {
                setProductIndex(nextProduct);
            }
        }
    }

    const toPrevProduct = (step = 1) => {
        if (allowChangeProduct) {
          setAllowChangeProduct(false);
          const prevProduct = productIndex - step
            const prevPage = parseInt(prevProduct / feedPage.page_size);
            if (prevProduct < 0) {
                setFeedPageIndex(feedPage.max_pages);
                setProductIndex(feedPage.total_products - 1);
                setReloadingProducts(true);
            } else if (prevPage < feedPageIndex) {
                setFeedPageIndex(prevPage);
                setProductIndex(prevProduct);
                setReloadingProducts(true);
            } else {
                setProductIndex(prevProduct);
            }
        }
    }

    const toSelectProduct = (index) => {
        setProductIndex(index)
    };

    const toFeedPageSelect = (page) => {
      setFeedPageIndex(page);
    };

    const findCustomFontsImages = (type, library) => {
        const itemsList = stage.find(type);

        const itemType = type === 'Image' ? 'image' : 'text';

        const itemsUsedInCanvas = itemsList
            .filter((designItem) => {
                if (itemType === 'image') {
                    return !designItem.attrs.dynamicField;
                }
                return true;
            })
            .map((mappingItem) => itemType === 'image' ? mappingItem.attrs.placeholder : mappingItem.attrs.fontUrl);

        const filteredItemsList = library.filter((item) => itemsUsedInCanvas.some((canvasItem) => canvasItem === (itemType === 'image' ? item.image : item.font) && item.isCustom));
        const uniqueList = removeDuplicates(filteredItemsList);

        return uniqueList.map(uniqueItem => uniqueItem.id);
    };

    const attachFilesToDesign = async (designId) => {
        try {
            const files = {
                images: findCustomFontsImages('Image', userImages),
                fonts: findCustomFontsImages('Text', api.fonts),
            };

            await axiosApi.patch(`v2/projects/designs/${designId}/`, files);

        } catch (error) {
            if (error.isAxiosError) {
                message.warning( error.message);
            } else {
                throw new Error(error);
            }
        }
    };

    const saveDesign = async () => {
        try {
            if (!designName) {
                message.error('Enter graphic name!');
                return;
            }
            roundPositionAndSize(stage);

            stage.findOne('Transformer').destroy();
            stage.findOne('#selectionRectangle').destroy();
            stage.findOne('#outlineLayer').destroy();
            stage.findOne('#captionLayer').destroy();
            stage.findOne('#canvas_bg').setAttrs({stroke: '',  strokeWidth: 0});
            const stage_json = stage.toJSON();

            const url = designId ?
                `/projects/${projectId}/designs/${designId}/` :
                `/projects/${projectId}/designs/`;

            const method = designId ? 'PUT' : 'POST';
            const {data} = await axiosApi({
                method,
                url,
                data: {
                    name: designName,
                    canvas_width: canvasSize.width,
                    canvas_height: canvasSize.height,
                    preset_name: stage.getAttr('preset_name'),
                    design_json: stage_json
                }
            });

            await attachFilesToDesign(data.id);

            message.success("Saved!");
            props.history.push({pathname: `/project/${projectId}/design/${data.id}`, search: window.location.search});
            window.location.reload();

        } catch (error) {
					initProject();
					if (error.isAxiosError) {
						handleGenerationsLimitError(error);
					} else {
						message.error(error.message);
					}
        }
    };

    const getImages = async (organizationId) => {
        try {
            const {data} = await axiosApi.get(`v2/projects/files/organization/${organizationId}`);
            const mergedImages = [
                ...data.uploaded_images.map(image => ({
                    ...image,
                    isCustom: true,
                })),
                ...data.default_images.map(image => ({
                    ...image,
                    isCustom: false,
                    id: Math.floor(Math.random() * 1000)
                }))
            ];
            setUserImages(mergedImages);

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };
    const uploadImage = async (file) => {
        try {
            if(!project) return;
            const formData = new FormData();
            formData.append('image', file, file.name);
            formData.append('name', file.name);
            formData.append('organization', project.organization.id);


            const response = await axiosApi.post('v2/projects/files/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            await getImages(project.organization.id);

            if (userImages && userImages.length) {
                const selectedImage = userImages.find(t => t.id === response?.data.id);
                if (selectedImage) {
                    selectedImage.selected = true;
                }
            }

            return response.data;
        } catch (error) {
            message.error(error.message);
            throw error;
        }
    };

    const deleteImage = async (imageId) => {
        try {
			if (!project) return;
            await axiosApi.delete(`v2/projects/files/${imageId}/`);
            await getImages(project.organization.id);

        } catch (e) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    const uploadFont =  async (file) => {
		if (!project) return;
		return await api.uploadFont(file, project.organization.id);
    };

    // elements
    const addText = (dynamicField, fontFamily, positions) => {
        const defaultText = dynamicField ? '' : 'Your text here...';
        const defaultFamily = fontFamily || 'Arial';
        const layer = stage.findOne('#canvas');
        const textGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'text',
            draggable: true,
            x: positions ? positions.x - 55 : canvasPosition.x,
            y: positions ? positions.y - 25 : canvasPosition.y,
        })
        textGroup.on('transformend', handleTransformEnd)
        const rect = new Konva.Rect({
            draggable: false,
            name: 'element background',
            elementType: 'text',
            fill: 'rgba(0,0,0,0)',
            width: 110,
            height: 50,
        })
        textGroup.add(rect)
        const substrate = new Konva.Rect({
            draggable: false,
            name: 'element noSnap substrate',
            elementType: 'text',
            fill: 'rgba(0,0,0,0)',
            width: 110,
            height: 50,
            substrateLeft: 0,
            substrateRight: 0,
            substrateTop: 0,
            substrateBottom: 0,
            substrateToggle: 0,
            perfectToggle: false,
            cornerRadius: [0, 0, 0, 0]
        })
        textGroup.add(substrate);
        const foundFont = api.fonts.find((font) => font.family === defaultFamily);

        const text = new Konva.Text({
            draggable: false,
            name: 'element main',
            elementType: 'text',
            dynamicField: dynamicField,
            text: dynamicField ? product[dynamicField] : defaultText,
            placeholder: defaultText,
            fullText: dynamicField ? product[dynamicField] : defaultText,
            fontFamily: defaultFamily,
            fontUrl: foundFont.font,
            fontSize: 20,
            align: 'center',
            verticalAlign: 'middle',
            maxLines: 3,
            minFontSize: 10,
            maxFontSize: 20,
            cutText: true,
        })

        if (!dynamicField) {
            SetEditableByDblClick(text, stage, () => {
                refreshProperties();
            });
        }

        textGroup.add(text)
        layer.add(textGroup)
        layer.draw()
        scaleText(text)
        refreshLayersStructure()
        saveHistory(stage);
    }

    const addImage = (dynamicField, positions) => {
        const layer = stage.findOne('#canvas');
        const imgGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'image',
            draggable: true,
            x: positions ? positions.x - 100 : canvasPosition.x,
            y: positions ? positions.y - 100 : canvasPosition.y,
        })
        imgGroup.on('transformend', handleTransformEnd);
        const rect = new Konva.Rect({
            draggable: false,
            name: 'element background',
            elementType: 'image',
            width: 200,
            height: 200,
            opacity: 0
        })
        imgGroup.add(rect);
        const imgUrl = dynamicField ? product[dynamicField] : DEFAULT_IMAGE
        const imageObj = new window.Image();
        imageObj.onload = function () {
            const imgNode = new Konva.Image({
                image: imageObj,
                draggable: false,
                name: 'element main',
                elementType: 'image',
                dynamicField: dynamicField,
                placeholder: DEFAULT_IMAGE,
                url: imgUrl,
                mode: 'fit',
                initWidth: imageObj.width,
                initHeight: imageObj.height,
            });
            rect.fillPatternImage(imageObj);
            imgGroup.add(imgNode);
            layer.add(imgGroup)
            layer.draw()
            scaleImage(imgNode)
            refreshLayersStructure();
            saveHistory(stage);
        };
        imageObj.src = imgUrl;
    }

    const addRect = () => {
        const layer = stage.findOne('#canvas');
        const shapeGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'rect',
            draggable: true,
            x: canvasPosition.x,
            y: canvasPosition.y,
        })
        shapeGroup.on('transformend', handleTransformEnd);
        const shape = new Konva.Rect({
            draggable: false,
            name: 'element background',
            elementType: 'rect',
            width: 200,
            height: 200,
            fill: 'red',
        })
        shapeGroup.add(shape);
        layer.add(shapeGroup);
        refreshLayersStructure();
        saveHistory(stage);
    }

    const addStar = () => {
        const layer = stage.findOne('#canvas');
        const shapeGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'star',
            draggable: true,
            x: canvasPosition.x,
            y: canvasPosition.y,
        })
        shapeGroup.on('transformend', handleTransformEnd);
        const shape = new Konva.Star({ // https://konvajs.org/api/Konva.Star.html
            draggable: false,
            name: 'element background',
            elementType: 'star',
            width: 200,
            height: 200,
            fill: 'red',
            numPoints: 5,
            innerRadius: 50,
            outerRadius: 100,
            offset: {x: -100, y: -100}
        })
        shapeGroup.add(shape);
        layer.add(shapeGroup);
        refreshLayersStructure();
        saveHistory(stage);
    }

    const addPolygon = () => {
        const layer = stage.findOne('#canvas');
        const shapeGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'polygon',
            draggable: true,
            x: canvasPosition.x,
            y: canvasPosition.y,
        })
        shapeGroup.on('transformend', handleTransformEnd);
        const shape = new Konva.RegularPolygon({ // https://konvajs.org/api/Konva.RegularPolygon.html
            draggable: false,
            name: 'element background',
            elementType: 'polygon',
            fill: 'red',
            sides: 7,
            radius: 100,
        })
        const size = shape.getClientRect();
        shape.offset({x: size.x, y: size.y});
        shapeGroup.add(shape);
        layer.add(shapeGroup);
        refreshLayersStructure();
        saveHistory(stage);
    }

    const addEllipse = () => {
        const layer = stage.findOne('#canvas');
        const shapeGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'ellipse',
            draggable: true,
            x: canvasPosition.x,
            y: canvasPosition.y,
        })
        shapeGroup.on('transformend', handleTransformEnd);
        const shape = new Konva.Ellipse({ // https://konvajs.org/api/Konva.Ellipse.html
            draggable: false,
            name: 'element background',
            elementType: 'ellipse',
            width: 200,
            height: 200,
            fill: 'red',
            offset: {x: -100, y: -100}
        })
        shapeGroup.add(shape);
        layer.add(shapeGroup);
        refreshLayersStructure();
        saveHistory(stage);
    }

    const addLine = () => {
        const layer = stage.findOne('#canvas');
        const shapeGroup = new Konva.Group({
            name: 'elementGroup',
            elementType: 'line',
            draggable: true,
            x: canvasPosition.x + 100,
            y: canvasPosition.y + 100,
        })
        shapeGroup.on('transformend', handleTransformEnd);
        const shape = new Konva.Line({ // https://konvajs.org/api/Konva.Line.html
            draggable: false,
            name: 'element background',
            elementType: 'line',
            width: 200, // TODO remove
            // height: 200, // TODO remove
            fill: 'black', // TODO remove
            stroke: 'black',
            points: [0, 0, 200, 0],
        })
        shapeGroup.add(shape);
        layer.add(shapeGroup);
        refreshLayersStructure();
        saveHistory(stage);
    }

    const createGroup = () => {
        const tr = stage.findOne('Transformer')
        const nodes = tr.nodes()
        if (!nodes.length) {
            message.error("Nothing to group").then(r => console.error(r))
            return
        }
        let layer, outerDepth = 1;
        if (new Set(nodes.map(node => node.parent.id)).size > 1) {
            for (const element of nodes) {
                let elementParent = element.parent, depth = 0
                while (depth < maxDepth) {
                    if (elementParent && elementParent.getAttr('elementType') === 'group') {
                        message.error("You can't group elements from side groups!")
                            .then(r => console.error(r))
                        return
                    } else if (!elementParent) {
                        break
                    }
                    elementParent = elementParent.parent
                    depth++
                }
            }
            layer = stage.findOne('#canvas');
        } else {
            let elementParent = nodes[0].parent
            while (elementParent.getAttr('elementType') === 'group') {
                elementParent = elementParent.parent
                outerDepth++
            }
            layer = nodes[0].parent
        }
        const findGroups = (elementsList, depth) => {
            const results = []
            for (const group of elementsList) {
                if (depth < maxDepth) {
                    results.push(findGroups(
                        group.children.filter(o => o.getAttr('elementType') === 'group'),
                        ++depth
                    ))
                } else {
                    results.push(true)
                }
            }
            return !!results.find(result => result === true)
        }
        if (outerDepth > maxDepth) {
            message.error("You can't nest more then 3 times!").then(r => console.error(r))
            return
        }
        if (findGroups(nodes.filter(o => o.getAttr('elementType') === 'group'), outerDepth)) {
            message.error("You can't nest more then 3 times!").then(r => console.error(r))
            return
        }

        const minPosition = {
            x: Math.min(...nodes.map(o => o.x())),
            y: Math.min(...nodes.map(o => o.y())),
        }
        const group = new Konva.Group({
            name: 'elementGroup',
            elementType: 'group',
            draggable: true,
            ...minPosition
        })
        group.on('transformend', handleTransformEnd)
        layer.add(group)
        nodes.sort((a, b) => a.zIndex() - b.zIndex());
        for (const el of nodes) {
            el.draggable(false)
            el.position({
                x: el.x() - minPosition.x,
                y: el.y() - minPosition.y,
            })
            el.moveTo(group)
        }
        tr.nodes([group])
        setSelectedGroup(group)
        saveHistory(stage);
        return group;
    }

    const distributeGroup = (group = selectedGroup, direction = 'left', align = '1', offset = 0) => {
        if (group instanceof Konva.Transformer) {
            group = createGroup()
        }
        if (group === undefined) {
            message.error("Nothing to distribute").then(r => console.error(r))
            return
        }

        if (!group.hasName('distributed')) {
            group.addName('distributed')
        }
        group.setAttr('distributeDirection', direction)
        group.setAttr('distributeAlign', align)
        group.setAttr('distributeOffset', offset)

        const translationTarget = {
            'left1': '3',
            'left2': '6',
            'left3': '9',
            'right1': '1',
            'right2': '4',
            'right3': '7',
            'up1': '7',
            'up2': '8',
            'up3': '9',
            'down1': '1',
            'down2': '2',
            'down3': '3',
        }

        const translationSource = {
            'left1': '1',
            'left2': '4',
            'left3': '7',
            'right1': '3',
            'right2': '6',
            'right3': '9',
            'up1': '1',
            'up2': '2',
            'up3': '3',
            'down1': '7',
            'down2': '8',
            'down3': '9',
        }

        const modifyTargetElement = (group, target, elements, direction, align) => {
            if (!target || !elements || elements.length === 0) {
                return;
            }

            const groupRect = group.getClientRect();
            const targetRect = target.getClientRect();

            const firstElement = elements.sort((a, b) => a.zIndex() - b.zIndex())[0];
            let x = firstElement.x();
            let y = firstElement.y();

            if (direction === 'up' && align === '1') {
                x = 0;
                y = 0;
            } else if (direction === 'up' && align === '2') {
                x = (groupRect.width - targetRect.width) / 2;
                y = 0;
            } else if (direction === 'up' && align === '3') {
                x = groupRect.width - targetRect.width;
                y = 0;
            } else if (direction === 'down' && align === '1') {
                x = 0;
                y = groupRect.height - targetRect.height;
            } else if (direction === 'down' && align === '2') {
                x = (groupRect.width - targetRect.width) / 2;
                y = groupRect.height - targetRect.height;
            } else if (direction === 'down' && align === '3') {
                x = groupRect.width - targetRect.width;
                y = groupRect.height - targetRect.height;
            }

            target.x(x);
            target.y(y);
        }

        group.children.forEach(child => child.hasName('source') && detachElement(child))
        const visible = group.children.filter(child => child.getAttr('invisible') !== true)
        visible.sort((a, b) => a.zIndex() - b.zIndex());
        let target = visible[0];

        modifyTargetElement(group, target, group.children, direction, align);

        for (const source of visible.slice(1)) {
            let offsetX = 0;
            let offsetY = 0;

            switch (direction) {
                case "left":
                    offsetX = offset
                    break
                case "right":
                    offsetX = -offset
                    break
                case "up":
                    offsetY = offset
                    break
                default: // "down"
                    offsetY = -offset
            }

            attachToElement(source, target, translationTarget[direction + align], translationSource[direction + align], offsetX, offsetY)
            target = source;
        }
        const tr = stage.findOne('Transformer');
        tr.nodes(tr.nodes())
        refreshProperties()
    }

    const reDistributeGroup = (group = selectedGroup) => {
        const direction = group.getAttr('distributeDirection')
        const align = group.getAttr('distributeAlign')
        const offset = group.getAttr('distributeOffset')
        distributeGroup(group, direction, align, offset)
    }

    const destroyDistributedGroup = (group = selectedGroup) => {
        if (!group.hasName('distributed')) {
            return
        }
        group.removeName('distributed')
        group.setAttr('distributeDirection', undefined)
        group.setAttr('distributeAlign', undefined)
        group.setAttr('distributeOffset', undefined)

        for (const el of group.children.filter(c => c.hasName('source'))) {
            detachElement(el)
        }
    }

    const getCoordsByAttachMode = (target, attachMode, scale) => {
        const targetSize = target.getClientRect();
        const targetWidth = targetSize.width / scale.x;
        const targetHeight = targetSize.height / scale.y;

        let deltaX = 0;
        let deltaY = 0;

        if (target.getAttr('elementType') === 'text') {
            const substrate = target.findOne('.substrate')
            deltaX = -substrate.x()
            deltaY = -substrate.y()
        }

        switch (attachMode) {
            case "2":
                return {
                    x: deltaX + targetWidth / 2,
                    y: deltaY
                }
            case "3":
                return {
                    x: deltaX + targetWidth,
                    y: deltaY
                }
            case "4":
                return {
                    x: deltaX,
                    y: deltaY + targetHeight / 2
                }
            case "5":
                return {
                    x: deltaX + targetWidth / 2,
                    y: deltaY + targetHeight / 2
                }
            case "6":
                return {
                    x: deltaX + targetWidth,
                    y: deltaY + targetHeight / 2
                }
            case "7":
                return {
                    x: deltaX,
                    y: deltaY + targetHeight
                }
            case "8":
                return {
                    x: deltaX + targetWidth / 2,
                    y: deltaY + targetHeight
                }
            case "9":
                return {
                    x: deltaX + targetWidth,
                    y: deltaY + targetHeight
                }
            default:
                return {
                    x: deltaX,
                    y: deltaY
                }
        }
    }

    const getAttachedSourcePosition = (target, attachMode, source, sourceAttachMode = '1', offsetX = 0, offsetY = 0) => {
        if (!target || !source) {
            return;
        }
        const scale = target.getStage().scale()
        const sourceCoords = getCoordsByAttachMode(source, sourceAttachMode, scale);

        let defaultX = target.x() + offsetX - sourceCoords.x;
        let defaultY = target.y() + offsetY - sourceCoords.y;

        if (target.getAttr('elementType') === 'text') {
            target = target.findOne('.substrate')
            defaultX = defaultX + target.x()
            defaultY = defaultY + target.y()
        }

        const targetSize = target.getClientRect();

        const targetWidth = targetSize.width / scale.x;
        const targetHeight = targetSize.height / scale.y;

        switch (attachMode) {
            case "1":
                return {
                    x: defaultX,
                    y: defaultY
                }
            case "2":
                return {
                    x: defaultX + targetWidth / 2,
                    y: defaultY
                }
            case "4":
                return {
                    x: defaultX,
                    y: defaultY + targetHeight / 2
                }
            case "5":
                return {
                    x: defaultX + targetWidth / 2,
                    y: defaultY + targetHeight / 2
                }
            case "6":
                return {
                    x: defaultX + targetWidth,
                    y: defaultY + targetHeight / 2
                }
            case "7":
                return {
                    x: defaultX,
                    y: defaultY + targetHeight
                }
            case "8":
                return {
                    x: defaultX + targetWidth / 2,
                    y: defaultY + targetHeight
                }
            case "9":
                return {
                    x: defaultX + targetWidth,
                    y: defaultY + targetHeight
                }
            default:  // "3"
                return {
                    x: defaultX + targetWidth,
                    y: defaultY
                }
        }
    }

    const attachToElement = (source, target, targetMode = '3', sourceMode = '1', offsetX = 0, offsetY = 0) => {
        detachElement(source);

        const noLoop = (checkId, currentTarget) => {
            if (currentTarget._id === checkId) {
                return false
            }
            if (currentTarget.hasName('source')) {
                const attachedTarget = stage.find('.target').find(
                    e => e.getAttr('attachIds').indexOf(currentTarget.getAttr('attachId')) >= 0
                )
                return noLoop(checkId, attachedTarget)
            }
            return true
        }

        if (source.getAttr('elementType') === 'group') {
            for (const child of source.children) {
                if (!noLoop(source._id, child)) {
                    message.error('Loop detected.')
                    return false
                }
            }
        }
        if (!noLoop(source._id, target)) {
            message.error('Loop detected.')
            return false
        }

        const attachId = stage.findOne('.source') ? Math.max(...stage.find('.source').map(el => el.getAttr('attachId'))) + 1 : 0

        source.addName(`source`)
        source.setAttr('attachId', attachId)
        source.setAttr('attachMode', targetMode)
        source.setAttr('sourceAttachMode', sourceMode)
        source.setAttr('attachX', offsetX)
        source.setAttr('attachY', offsetY)

        if (target.getAttr('attachIds')) {
            const ids = target.getAttr('attachIds')
            ids.push(attachId)
            target.setAttr('attachIds', ids)
        } else {
            target.addName(`target`)
            target.setAttr('attachIds', [attachId])
        }

        const newPosition = getAttachedSourcePosition(target, targetMode, source, sourceMode, offsetX, offsetY)
        source.position(newPosition);

        setSelectedElementPosition({
          x: target.x(),
          y: target.y()
        });
        refreshProperties()
    }

    const detachElement = (source) => {
        if (!source.hasName('source')) return;
        const attachId = source.getAttr('attachId')
        const attachedTarget = stage.find('.target').find(
            e => e.getAttr('attachIds').indexOf(attachId) >= 0
        )
        if (!attachedTarget) return;

        source.removeName(`source`)
        source.setAttr('attachId', undefined)
        source.setAttr('attachMode', undefined)
        source.setAttr('attachX', undefined)
        source.setAttr('attachY', undefined)
        if (attachedTarget.getAttr('attachIds').length > 1) {
            let ids = attachedTarget.getAttr('attachIds')
            ids = ids.filter(t => t !== attachId);
            attachedTarget.setAttr('attachIds', ids)
        } else {
            attachedTarget.removeName(`target`)
            attachedTarget.setAttr('attachIds', undefined)
        }
        refreshProperties()
    }

    const calculatePosition = (element, stage, scale) => {
        if (!element) {
            return;
        }

        const absolutePosition = element.absolutePosition();
        return {
            x: (absolutePosition.x - stage.x()) / scale,
            y: (absolutePosition.y - stage.y()) / scale
        }
    }

    const moveAttachedSources = (target) => {
        try {
            const stage = target.getStage();
            const attachIds = target.getAttr('attachIds');

            if (!attachIds) {
                return;
            }

            for (const attachId of attachIds) {
                const source = stage.find('.source').find(
                    e => e.getAttr('attachId') === attachId
                )

                const scale = stage.scaleX();
                let newTargetPosition = calculatePosition(target, stage, scale);

                if (isParentGroupElement(target)) {
                    const groupPosition = calculatePosition(target.parent, stage, scale);
                    newTargetPosition = {
                        x: newTargetPosition.x - groupPosition.x,
                        y: newTargetPosition.y - groupPosition.y
                    }
                }

                target.position(newTargetPosition)

                if (source) {
                    const newSourcePosition = getAttachedSourcePosition(target, source.getAttr('attachMode'), source, source.getAttr('sourceAttachMode'),
                        source.getAttr('attachX'), source.getAttr('attachY'))

                    source.position(newSourcePosition)
                    if (source.hasName('target')) {
                        moveAttachedSources(source)
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const updateSourceAttachedOffset = (source) => {
        const currentStage = source.getStage();
        const attachId = source.getAttr('attachId')
        const attachedTarget = currentStage.find('.target').find(
            e => e.getAttr('attachIds').indexOf(attachId) >= 0
        )
        const newPosition = getAttachedSourcePosition(attachedTarget, source.getAttr('attachMode'), source, source.getAttr('sourceAttachMode'))
        source.setAttr('attachX', source.x() - newPosition.x)
        source.setAttr('attachY', source.y() - newPosition.y)
    }

    const isParentGroupElement = (element) => {
        return element && element.parent && element.parent.getAttr('elementType') === 'group';
    }

    const destroyGroup = (group) => {
        const tr = stage.findOne('Transformer');

        const children = [...group.children];
        children.forEach(t => {
            t.draggable(!isParentGroupElement(group));
            t.position({
                x: t.x() + group.x(),
                y: t.y() + group.y(),
            })
            t.moveTo(group.parent);
        })

        if (tr.nodes().includes(group)) tr.nodes([])
        setSelectedGroup(null)
        group.destroy()
    }

    const deleteElement = (element, isHistory = false) => {
        if (!element) {
            return;
        }

        const parentElement = element?.parent;
        const tr = stage.findOne('Transformer');

        if (element instanceof Konva.Transformer) {
            const elems = tr.nodes();
            tr.nodes([]);
            elems.forEach(elem => elem.destroy())
        } else if (tr.nodes().includes(element)) {
            tr.nodes([])
            element.destroy();
        } else {
            element.destroy();
            refreshProperties();
        }

        if (parentElement.attrs.elementType === 'group' && parentElement.children.length === 0) {
          parentElement.destroy();
        }

        setSelectedGroup(null);
        refreshLayersStructure();
        if (isHistory) return;
        saveHistory(stage);

        const menuNode = document.getElementById('menu');
        menuNode.style.display = 'none';
    }

    const copyElement = (element) => {
        if (!element) return;
        const transformer = stage.findOne('Transformer');

        const parent = element.getParent();
        const newElement = element.clone();
        newElement.x(newElement.x() + 50).y(newElement.y() + 50);
        newElement.setAttr('elementName', '');

        parent.add(newElement);
        parent.draw();

        setSelectedGroup(newElement);
        transformer.nodes([newElement]);
        refreshLayersStructure();
        saveHistory(stage)
    }

    const hideElement = (element) => {
        const opacity = element.opacity();
        const transformer = stage.findOne('Transformer');
        element.opacity(opacity ? 0 : 1);
        setSelectedGroup(null);
        if (transformer) {
            transformer.nodes([]);
        }
        refreshLayersStructure();
        saveHistory(stage);
    };

    const closeProperties = () => {
        setSelectedGroup(null);
    };

    const closeOpenLayersPanel = () => {
        setLayersPanelOpen(!isLayersPanelOpen);
    }

    const closeOpenProductData = () => {
        setProductDataPanelOpen(!isProductDataPanelOpen);

        if (!isProductDataPanelOpen) {
            selectFromLayout([]);
        }
    }

    const closeOpenFontsPanel = () => {
        setFontsPanelOpen(!isFontsPanelOpen);
    }

    const layerRef = useRef();

    const refreshLayersStructure = () => {
        if (layerRef.current) {
            layerRef.current.refresh();
        }
    }

    const selectFromLayout = (elements) => {
        const tr = stage.findOne('Transformer');
        tr.nodes(elements);
        if (tr.nodes().length === 1) {
            setSelectedGroup(tr.nodes()[0]);
        } else if (tr.nodes().length > 1) {
            setSelectedGroup(tr);
        } else {
            setSelectedGroup(null);
        }
    }

    const addTextWithFont = (font) => {
        addText(undefined, font.family);
    }

    const getDesignViewName = (designName, presetId, width, height) => {
        const actualPreset = presetsDataSource.find(t => t.id === presetId);
        let actualPresetName = '';
        if (actualPreset) {
            actualPresetName = !actualPreset.customSize ? actualPreset.name : `${actualPreset.name} ${width}x${height} px`;
        }
        return designName + (actualPresetName ? ` (${actualPresetName})` : '');
    }

    const onDesignPropertiesChanged = (name, presetId, width, height) => {
        setDesignName(name);
        setPreset(presetId);

        if (width && height) {
            setCanvasSize({width: width, height: height});
        }
    }

    const isEmptyDynamicProductValue = (element) => {
        if (!element || !product) {
            return false;
        }

        const textElements = element.find('Text');
        const isDynamicField = textElements.length && textElements[0].getAttr('dynamicField');
        if (isDynamicField) {
            const textElement = textElements[0];
            const textValue = product[textElement.getAttr('dynamicField')] ? product[textElement.getAttr('dynamicField')] : textElement.getAttr('placeholder');
            return !textValue;
        }

        return false;
    }

    const checkCondition = (element) => {
        if (!element) {
            return;
        }

        const condition = element.getAttr('condition');
        if (condition) {
            if (isEmptyDynamicProductValue(element)) {
                setElementVisibility(element, false);
            } else {
                const conditionComplete = calculateCondition(createViewModel(condition), product);
                setElementVisibility(element, conditionComplete);
            }

            if (element.parent && element.parent.hasName('distributed')) {
                reDistributeGroup(element.parent);
            }
        } else {
          setElementVisibility(element, true);
        }
    }

    const propertyChanged = () => {
        saveHistory(stage);
    }

    const exit = () => {
        urlHistory.push({pathname: `/project/${projectId}/rule/${ruleId}`, search: window.location.search});
    }

    const searchProductById = async (productId) => {
        if (!project) return;
        const foundProduct = await props.backend.getProductById(project?.feed.id, productId, setReloadingProducts)
        if (!foundProduct) return;
        setProduct(foundProduct);
    };

    const onDraggingElementFinish = async (e, type, value=null) => {
        e.preventDefault();
        await stage.setPointersPositions(e);
        const containerRect = stage.container().getBoundingClientRect();
        const positions = {
            x: stage.getRelativePointerPosition().x - containerRect.x + layerDiff,
            y: stage.getRelativePointerPosition().y - containerRect.y + headerTop,
        }

        if (type === 'text') return  addText(value, null, positions);
        if (type === 'image') return addImage(value, positions)
    };


    return (
        <>
            <GraphicEditorHeader
                exit={exit}
                addLine={addLine}
                addRect={addRect}
                addStar={addStar}
                addText={addText}
                addImage={addImage}
                addPolygon={addPolygon}
                addEllipse={addEllipse}
                saveDesign={saveDesign}
                isCanvasOn={clipOutline}
                canvasOnOff={canvasOnOff}
                setDesignName={setDesignName}
                onLayersClick={closeOpenLayersPanel}
                isLayersPanelOpen={isLayersPanelOpen}
                onProductDataClick={closeOpenProductData}
                productDataLoading={reloadingProducts}
            />
            <div style={{height: '100%', backgroundColor: '#fcfcfc'}}>
                {isProductDataPanelOpen && <ProductData product={product}
                                                        productFields={productFields ?? {}}
                                                        productsTotalCount={feedPage?.total_products ?? 0}
                                                        pageSize={feedPage?.page_size}
                                                        pageNumber={feedPageIndex}
                                                        reloadingProducts={reloadingProducts || !allowChangeProduct }
                                                        productIndex={productIndex}
                                                        toPrevProduct={toPrevProduct}
                                                        toNextProduct={toNextProduct}
                                                        toSelectProduct={toSelectProduct}
                                                        toFeedPageSelect={toFeedPageSelect}
                                                        addText={(name) => {
                                                            addText(name);
                                                            closeOpenProductData()
                                                        }}
                                                        addImage={(name) => {
                                                            addImage(name);
                                                            closeOpenProductData()
                                                        }}
                                                        onDraggingElementFinish={onDraggingElementFinish}
                                                        onClose={closeOpenProductData}
                                                        searchProductById={searchProductById}
                                                        inGraphicEditor
                />}
                {selectedGroup && <GraphicProperties product={product}
                                                     productFields={productFields}
                                                     selectedGroup={selectedGroup}
                                                     stage={stage}
                                                     scaleText={scaleText}
                                                     scaleImage={scaleImage}
                                                     attachToElement={attachToElement}
                                                     onCloseProperties={closeProperties}
                                                     uploadFile={uploadImage}
                                                     userImages={userImages}
                                                     deleteImage={deleteImage}
                                                     refreshToggle={propertiesRefreshToggle}
                                                     distributeGroup={distributeGroup}
                                                     destroyDistributedGroup={destroyDistributedGroup}
                                                     detachElement={detachElement}
                                                     fonts={api.fonts}
                                                     checkCondition={checkCondition}
                                                     uploadFont={uploadFont}
                                                     onPropertyChanged={propertyChanged}
                                                     reloadingProducts={reloadingProducts}
                />}
                {isLayersPanelOpen && stage &&
                    <LayersPanel
                        ref={layerRef}
                        maxDepth={maxDepth}
                        stage={stage}
                        refreshToggle={propertiesRefreshToggle}
                        destroyGroup={destroyGroup}
                        onSelectField={selectFromLayout}
                        onClose={closeOpenLayersPanel}
                        reDistributeGroup={reDistributeGroup}
                        onCopyElement={copyElement}
                        onDeleteElement={deleteElement}
                        onChangeName={refreshProperties}
                        saveHistory={saveHistory}
                        hideElement={hideElement}
                    />
                }
                {isFontsPanelOpen && <FontsPanel
                    onClose={closeOpenFontsPanel}
                    uploadFont={api.uploadFont}
                    deleteFont={api.deleteFont}
                    fonts={api.user.fonts}
                    onFontClick={addTextWithFont}
                />}
                {isProductPropertiesOpen && <ProjectProperties setProperties={onDesignPropertiesChanged}
                                                               designName={designName}
                                                               width={canvasSize.width}
                                                               height={canvasSize.height}
                                                               presetsDataSource={presetsDataSource}
                                                               preset={preset}
                                                               close={showProjectPropertyForm}/>}
                <div
                    id="konva_container"
                    style={styles.konva}
                    onDragOver={(e) => e.preventDefault()}
                />
                <GraphicEditorTooltip
                    createGroup={createGroup}
                    destroyGroup={destroyGroup}
                    copyElement={copyElement}
                    deleteElement={deleteElement}
                    selectedGroup={selectedGroup}
                />

                <ControlsPanel sidebarWidth={sidebarWidth}
                               onPrevProduct={toPrevProduct}
                               onNextProduct={toNextProduct}
                               onZoomIn={() => zoomStage(1)}
                               onZoomOut={() => zoomStage(-1)}
                               history={history}
                               onHistoryUndo={historyUndoAction}
                               onHistoryRedo={historyRedoAction}
                />
            </div>
        </>)
}

export default withRouter(GraphicEditor);
